import "solid-devtools";

import { mergeProps, splitProps } from "solid-js";
import { AudioPlayer, VideoPlayer } from "src/components";
import { PlayerContext } from "./infra/PlayerContext";
import { DEFAULT_STATE, useColorScheme } from "src/infra";
import { SolidPlayerProps } from "src/types";

import "src/styles/index.scss";
import { createStore } from "solid-js/store";

export function SolidPlayer(propsOriginal: SolidPlayerProps) {
  const { colorScheme } = useColorScheme();

  const props = mergeProps({ type: "video" } as const, propsOriginal);
  const [{ type }, playerProps] = splitProps(props, ["type"]);

  const PlayerComponent = type === "audio" ? AudioPlayer : VideoPlayer;

  return (
    <PlayerContext.Provider
      value={createStore(
        mergeProps(DEFAULT_STATE, {
          muted: props.muted,
          volume: props.volume,
          currentTime: props.currentTime,
          props: playerProps,
        })
      )}
    >
      <PlayerComponent {...playerProps} theme={props.theme ?? colorScheme()} />
    </PlayerContext.Provider>
  );
}
