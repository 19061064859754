import { createSignal, onCleanup, onMount } from "solid-js";
import styles from "./VolumeRocker.module.scss";
import { createElementBounds } from "@solid-primitives/bounds";
import { clamp } from "src/infra";
import { VolumeHighIcon, VolumeLowIcon, VolumeMuteIcon } from "src/icons";
import c from "classnames";
import { VolumeRockerProps } from "./types";

export function DesktopVolumeRocker(props: VolumeRockerProps) {
  const [mouseDown, setMouseDown] = createSignal(false);
  let [trackRef, setTrackRef] = createSignal<HTMLDivElement>();
  const trackRect = createElementBounds(trackRef);

  const padding = 6;

  const update = (clientX: number) => {
    if (!trackRef) return;
    const w = trackRect.width! - padding * 2 - 4;
    const x = clientX - trackRect.left! - padding - 2;
    props.onLevelChange(clamp(0, (x / w) * 100, 100) / 100);
  };

  function onMouseMove(e: MouseEvent): void {
    if (!mouseDown()) return;

    update(e.clientX);
  }

  function onTouchMove(e: TouchEvent): void {
    if (!mouseDown()) return;

    update(e.touches[0]!.clientX);
  }

  function onMouseUp(e: MouseEvent): void {
    if (!mouseDown()) return;

    setMouseDown(false);

    update(e.clientX);
  }

  function onTouchEnd(_e: TouchEvent): void {
    setMouseDown(false);
  }

  function onMouseLeave(): void {
    setMouseDown(false);
  }

  function onTouchCancel(): void {
    setMouseDown(false);
  }

  onMount(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mouseleave", onMouseLeave);
    document.addEventListener("touchend", onTouchEnd);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchcancel", onTouchCancel);
  });

  onCleanup(() => {
    document.removeEventListener("mousemove", onMouseMove);
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mouseleave", onMouseLeave);
    document.removeEventListener("touchend", onTouchEnd);
    document.removeEventListener("touchmove", onTouchMove);
    document.removeEventListener("touchcancel", onTouchCancel);
  });

  const getVolumeIcon = () => {
    if (props.level === 0 || props.muted) {
      return <VolumeMuteIcon />;
    } else if (props.level <= 0.5) {
      return <VolumeLowIcon />;
    } else {
      return <VolumeHighIcon />;
    }
  };

  return (
    <div class={c(styles.volumeRocker, props.class)}>
      <button
        class={styles.volumeButton}
        type="button"
        onClick={() => {
          if (props.level === 0) {
            props.onLevelChange(0.5);
          } else {
            props.onMutedChange(!props.muted);
          }
        }}
      >
        {getVolumeIcon()}
      </button>

      <div
        class={styles.track}
        ref={setTrackRef}
        onMouseDown={(e: MouseEvent) => {
          setMouseDown(true);
          update(e.clientX);
        }}
        onTouchStart={(e: TouchEvent) => {
          if (e.touches.length !== 1) return;

          setMouseDown(true);
          update(e.touches[0].clientX);
        }}
      >
        {trackRect.width && (
          <div
            class={styles.trackFilled}
            style={{
              width:
                (trackRect.width! - padding * 2) *
                  (props.muted ? 0 : props.level) +
                "px",
            }}
          />
        )}
        {trackRect.width && (
          <div
            class={styles.thumb}
            style={{
              left:
                (trackRect.width! - padding * 2) *
                  (props.muted ? 0 : props.level) +
                "px",
            }}
          />
        )}
      </div>
    </div>
  );
}
