import { DropdownMenu } from "@kobalte/core";
import { createEffect, createSignal } from "solid-js";
import styles from "./DesktopMenuButton.module.scss";
import { BasicArrowRight, BasicCheck } from "src/icons";
import { ActiveOption, MenuButtonProps, Option } from "./types";
import { normalizeOption } from "src/infra";

export function DesktopMenuButton(props: MenuButtonProps) {
  const { onOpenChange, onSelect } = props;

  const [activeItem, setActiveItem] = createSignal<ActiveOption>(
    normalizeOption(props.options) as ActiveOption
  );

  createEffect(() => {
    setActiveItem(normalizeOption(props.options) as ActiveOption);
  });

  function handleItemClick(item: Option | ActiveOption) {
    if (item.items) {
      setActiveItem(item as ActiveOption);
    } else {
      onSelect?.(item, item.parent?.id ?? item.parent?.label);
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setActiveItem(normalizeOption(props.options) as ActiveOption);
    }
    onOpenChange?.(isOpen);
  }

  function renderContent() {
    return (
      <DropdownMenu.Content class={styles.Content}>
        <DropdownMenu.Group>
          <DropdownMenu.GroupLabel class={styles.GroupLabel}>
            {activeItem().label}
          </DropdownMenu.GroupLabel>

          {activeItem().items.map((item: Option) => {
            const isSelected = item.value === activeItem().value;

            return (
              <>
                {item.items ? (
                  <DropdownMenu.Item
                    class={styles.Item}
                    onSelect={() => handleItemClick(item)}
                    closeOnSelect={false}
                  >
                    <span class={styles.ItemLabel}>{item.label}</span>
                    <span class={styles.ItemValue}>{item.value}</span>
                    <BasicArrowRight class={styles.ItemIcon} />
                  </DropdownMenu.Item>
                ) : (
                  <DropdownMenu.Item
                    classList={{
                      [styles.Item]: true,
                      // [styles.ItemSelected]: isSelected,
                    }}
                    onSelect={() => handleItemClick(item)}
                    closeOnSelect
                  >
                    <span
                      classList={{
                        [styles.ItemIndicator]: true,
                        [styles.ItemIndicatorSelected]: isSelected,
                      }}
                    >
                      <BasicCheck />
                    </span>

                    <span class={styles.ItemLabel}>{item.label}</span>
                  </DropdownMenu.Item>
                )}
              </>
            );
          })}
        </DropdownMenu.Group>
      </DropdownMenu.Content>
    );
  }

  return (
    <DropdownMenu.Root
      open={props.open}
      onOpenChange={handleOpenChange}
      placement="top"
      modal
      fitViewport
    >
      {props.children && (
        <DropdownMenu.Trigger class={props.class}>
          {props.children}
        </DropdownMenu.Trigger>
      )}
      {props.inPortal && (
        <DropdownMenu.Portal>{renderContent()}</DropdownMenu.Portal>
      )}
      {!props.inPortal && renderContent()}
    </DropdownMenu.Root>
  );
}
