import { JSX } from "solid-js";

export const BasicArrowRight = (props: JSX.SvgSVGAttributes<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={7.071}
    height={11.314}
    {...props}
  >
    <g fill="currentColor">
      <path
        d="M7.071 5.657 5.657 7.071 0 1.414 1.414 0z"
        data-name="Rectangle 9"
      />
      <path
        d="m5.657 4.242 1.414 1.414-5.657 5.657L0 9.9z"
        data-name="Rectangle 10"
      />
    </g>
  </svg>
);
