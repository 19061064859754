import { createSignal, onCleanup, onMount } from "solid-js";

type ColorScheme = "dark" | "light";

type UseColorScheme = {
  colorScheme: () => ColorScheme;
};

const useColorScheme = (): UseColorScheme => {
  const media = window.matchMedia("(prefers-color-scheme: dark)");
  const [colorScheme, setColorScheme] = createSignal<ColorScheme>(
    media.matches ? "dark" : "light"
  );

  const handleMediaChange = (e: MediaQueryListEvent) => {
    setColorScheme(e.matches ? "dark" : "light");
  };

  onMount(() => {
    media.addEventListener("change", handleMediaChange);
  });

  onCleanup(() => {
    media.removeEventListener("change", handleMediaChange);
  });

  return {
    colorScheme,
  };
};

export default useColorScheme;
