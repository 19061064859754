import { ToggleButton } from "@kobalte/core";
import { MenuButton } from "../MenuButton/MenuButton";
import {
  EnterFullScreenButton,
  ExitFullScreenButton,
  SettingsActiveButton,
  SettingsButton,
} from "src/icons";
import {
  usePlayerContext,
  SPEED_OPTIONS,
  PlayerStateKeys,
  useCurrentSource,
  isHlsSource,
} from "src/infra";
import { createMemo, createSignal } from "solid-js";
import {
  SolidPlayerLanguage,
  SolidPlayerQuality,
  VideoPlayerProps,
} from "src/types";

import styles from "./SettingsControls.module.scss";
import iconSstyles from "../IconButton/IconButton.module.scss";
import { Option } from "src/components/MenuButton/types";
import { CastLauncherButton } from "./CastLauncherButton";

interface SettingsControlsProps {
  onStateItemChange: (key: PlayerStateKeys, value: any) => void;
}

export function SettingsControls(props: SettingsControlsProps) {
  const [state] = usePlayerContext();
  const { currentSource } = useCurrentSource(state!.props!.languages);
  const isLiveMemo = createMemo(
    () =>
      (state!.props! as VideoPlayerProps).hls && isHlsSource(currentSource())
  );

  const hasCC = createMemo(
    () => state.props?.tracks?.length != null && state.props.tracks.length > 0
  );

  const qualityOptions = createMemo(
    () => ({
      label: "ხარისხი",
      id: "quality",
      value: state.quality,
      items: [
        ...(state.props?.languages
          .find(({ label }: SolidPlayerLanguage) => label === state.language)
          ?.qualities.map(({ label }: SolidPlayerQuality) => ({
            label,
            value: label,
          })) ?? []),
        { label: "Auto", value: "Auto" },
      ],
    }),
    [state.language, state.quality]
  );

  const settingsOptions = createMemo(() => {
    const languageOptions =
      state.props?.languages.map(({ label }: SolidPlayerLanguage) => ({
        label,
        value: label,
      })) ?? [];

    return {
      label: "პარამეტრები",
      items: [
        {
          label: "ენა",
          id: "language",
          value: state.language,
          items: languageOptions,
        },
        !isLiveMemo() && {
          label: "დაკვრის სისწრაფე",
          id: "speed",
          value: state.speed,
          items: SPEED_OPTIONS,
        },
      ].filter(Boolean) as Option[],
    };
  }, [state.language, state.speed, isLiveMemo()]);

  const [settingsDropdownState, setSettingsDropdownState] = createSignal(false);
  const [qualityDropdownState, setQualityDropdownState] = createSignal(false);

  function handleMenuSelect(item: Option, id?: string) {
    if (id && item.value) {
      props.onStateItemChange(id as PlayerStateKeys, item.value);
    }
  }

  return (
    <div
      classList={{
        [styles.settingsControls]: true,
      }}
    >
      <MenuButton
        options={settingsOptions()}
        open={!state.isIdle && settingsDropdownState() === true}
        onOpenChange={() => setSettingsDropdownState((prev) => !prev)}
        onSelect={handleMenuSelect}
        inPortal={!state.fullscreen}
        class={iconSstyles.simpleIconButton}
      >
        {!settingsDropdownState() ? (
          <SettingsButton />
        ) : (
          <SettingsActiveButton />
        )}
      </MenuButton>

      {(state.props as VideoPlayerProps)?.chromeCast && (
        <CastLauncherButton
          class={iconSstyles.iconButton}
          onCastStateChanged={(isConnected: boolean) =>
            props.onStateItemChange("casting", isConnected)
          }
        />
      )}

      <MenuButton
        options={qualityOptions()}
        open={!state.isIdle && qualityDropdownState()}
        onOpenChange={() => setQualityDropdownState((prev) => !prev)}
        onSelect={handleMenuSelect}
        inPortal={!state.fullscreen}
        class={iconSstyles.simpleTextIconButton}
      >
        4K
      </MenuButton>

      {hasCC() && (
        <ToggleButton.Root
          class={iconSstyles.simpleTextIconButton}
          pressed={state.captionsIsActive}
          onChange={(value) =>
            props.onStateItemChange("captionsIsActive", value)
          }
        >
          CC
        </ToggleButton.Root>
      )}

      <button
        class={iconSstyles.simpleIconButton}
        onClick={() => props.onStateItemChange("fullscreen", !state.fullscreen)}
      >
        {state.fullscreen ? (
          <ExitFullScreenButton />
        ) : (
          <EnterFullScreenButton />
        )}
      </button>
    </div>
  );
}
