import { MountableElement, render } from "solid-js/web";
import { SolidPlayerProps } from "src/types";
import { SolidPlayer } from "./SolidPlayer";

function createPlayer(element: MountableElement, props: SolidPlayerProps) {
  const dispose = render(() => <SolidPlayer {...props} />, element);

  return { dispose };
}

if (typeof window !== "undefined") {
  (window as any).XSG = {
    createPlayer,
  };
}
