import { useResponsive } from "src/infra";
import { MobileMenuButton } from "./MobileMenuButton";
import { MenuButtonProps } from "./types";
import { DesktopMenuButton } from "./DesktopMenuButton";

export function MenuButton(props: MenuButtonProps) {
  const { isMobile } = useResponsive();

  return (
    // ternary doesn't work with SolidJS signals... WTF?
    <>
      {isMobile() && <MobileMenuButton {...props} />}
      {!isMobile() && <DesktopMenuButton {...props} />}
    </>
  );
}
