import {
  SolidPlayerLanguage,
  SolidPlayerQuality,
  SolidPlayerSource,
} from "src/types";
import { Option } from "src/components/MenuButton/types";
import { isEmpty, isNull, isObject, isUndefined } from "lodash";

export function formatTime(currentTime: number, total?: number) {
  const hours = Math.floor(currentTime / 3600);
  const minutes = Math.floor((currentTime - hours * 3600) / 60);
  const seconds = Math.floor(currentTime - hours * 3600 - minutes * 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (total != null ? total / 3600 >= 1 : false) {
    const formattedHours = String(hours).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
}

export interface TimeRange {
  start: number;
  end: number;
}

export function parseTimeRanges(ranges: TimeRanges): TimeRange[] {
  var result = [];
  for (var i = 0; i < ranges.length; i++) {
    result.push({
      start: ranges.start(i),
      end: ranges.end(i),
    });
  }
  return result;
}

export function clamp(min: number, val: number, max: number): number {
  return Math.max(min, Math.min(val, max));
}

export function normalizeOption(item: Option): Option {
  if (item.items) {
    return {
      ...item,
      items: item.items.map((subItem) => ({
        ...normalizeOption(subItem),
        parent: item,
      })),
    };
  } else {
    return item;
  }
}

type ScriptAttributes = {
  type?: string;
  async?: boolean;
  defer?: boolean;
  integrity?: string;
  crossorigin?: string;
  charset?: string;
  nonce?: string;
  [key: string]: any;
};

export async function loadScript(
  url: string,
  attributes: ScriptAttributes = {},
  callbackName?: string
): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = url;

    for (const [key, value] of Object.entries(attributes)) {
      script.setAttribute(key, value.toString());
    }

    script.async = true;

    if (callbackName) {
      (window as any)[callbackName] = resolve;
    } else {
      script.onload = () => {
        resolve(script);
      };
    }

    script.onerror = () => {
      reject(new Error(`Failed to load the script at: ${url}`));
    };

    document.head.appendChild(script);
  });
}

export function waitFor(
  predicate: () => boolean,
  timeout: number = 5000,
  interval: number = 100
): Promise<void> {
  return new Promise((resolve, reject) => {
    const startTime = Date.now();

    const checkCondition = (): void => {
      if (predicate()) {
        resolve();
      } else if (Date.now() - startTime >= timeout) {
        reject(
          new Error(
            `waitFor: waiting for condition has exceeded timeout (${timeout}ms)`
          )
        );
      } else {
        setTimeout(checkCondition, interval);
      }
    };

    checkCondition();
  });
}

export const findCurrentSource = (
  languages: SolidPlayerLanguage[],
  language: string,
  quality: string = "Auto"
) => {
  const currentLanguage =
    languages.find((lang: SolidPlayerLanguage) => lang.label === language) ??
    languages[0];

  if (!currentLanguage) {
    return null;
  }

  const currentQuality =
    currentLanguage.qualities.find(
      (qual: SolidPlayerQuality) => qual.label === quality || quality === "Auto"
    ) ?? currentLanguage.qualities[0];

  return currentQuality ? currentQuality.sources[0] : null;
};

export const isHlsSource = (source?: SolidPlayerSource | null) =>
  source?.src.endsWith(".m3u8") ?? false;

export const getExtension = (fileUrl: string) =>
  fileUrl.split(".").pop() as string;

export async function isResolved(promise: Promise<any>) {
  return await promise.then(
    () => true,
    () => false
  );
}

export async function isRejected(promise: Promise<any>) {
  return await promise.then(
    () => false,
    () => true
  );
}

export async function isFinished(promise: Promise<any>) {
  return await promise.then(
    () => true,
    () => true
  );
}

export const isFalsy = (obj: any, undefinedOnly = true) =>
  undefinedOnly
    ? isUndefined(obj)
    : isUndefined(obj) ||
      isNull(obj) ||
      obj === "" ||
      (isObject(obj) && isEmpty(obj));

export const withoutUndefined = (obj: any, withoutEmpty = false) => {
  if (!obj || Array.isArray(obj) || typeof obj !== "object") {
    return obj;
  }

  return Object.keys(obj).reduce((cleanedObj, key: string) => {
    if (isFalsy(obj[key], !withoutEmpty)) {
      return cleanedObj;
    } else {
      cleanedObj[key] = obj[key];
      return cleanedObj;
    }
  }, {} as any);
};
