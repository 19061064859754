import { createSignal, onCleanup, onMount } from "solid-js";

export const PHONE_BREAKPOINT = 600;

type UseResponsive = {
  isMobile: () => boolean;
  isDesktop: () => boolean;
};

const useResponsive = (): UseResponsive => {
  const media = window.matchMedia(`(max-width: ${PHONE_BREAKPOINT}px)`);
  const [isMobile, setIsMobile] = createSignal(media.matches);

  const handleMediaChange = (e: MediaQueryListEvent) => {
    setIsMobile(e.matches);
  };

  onMount(() => {
    media.addEventListener("change", handleMediaChange);
  });

  onCleanup(() => {
    media.removeEventListener("change", handleMediaChange);
  });

  return {
    isMobile,
    isDesktop: () => !isMobile(),
  };
};

export default useResponsive;
