export const CopiedButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} viewBox="0 0 48 48">
    <g transform="translate(9.35 -10.65)">
      <circle
        cx="15"
        cy="15"
        r="15"
        transform="translate(-.35 19.65)"
        fill="#fff"
      />
      <path
        d="m8.33 34 5.48 5.12 7.27-9.05"
        fill="none"
        stroke="#000"
        stroke-width="2"
      />
    </g>
  </svg>
);
