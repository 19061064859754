import { createContext, useContext } from "solid-js";
import { PlayerState } from "./store";
import { SetStoreFunction, Store } from "solid-js/store";

type PlayerContextType = [
  get: Store<PlayerState>,
  set: SetStoreFunction<PlayerState>
];

export const PlayerContext = createContext<PlayerContextType>();

export function usePlayerContext() {
  return useContext(PlayerContext)!;
}
