export const ExitFullScreenButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <g fill="currentColor">
      <path d="M18 32h-8v-2h8z" />
      <path d="M30 16h8v2h-8z" />
      <path d="M16 18v-8h2v8z" />
      <path d="M32 30v8h-2v-8z" />
      <path d="M18 30v8h-2v-8z" />
      <path d="M30 18v-8h2v8z" />
      <path d="M18 18h-8v-2h8z" />
      <path d="M30 30h8v2h-8z" />
    </g>
  </svg>
);
