export function VolumeLowIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.667 16C24.667 13.64 23.307 11.6134 21.3337 10.6267V21.36C23.307 20.3867 24.667 18.36 24.667 16ZM6.66699 12V20H12.0003L18.667 26.6667V5.33337L12.0003 12H6.66699Z"
        fill="currentColor"
      />
    </svg>
  );
}
