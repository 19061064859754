import {
  For,
  Show,
  createEffect,
  splitProps,
  untrack,
  onCleanup,
  createSignal,
} from "solid-js";
import { Waveform, VolumeRocker } from "src/components";
import { AudioPlayerProps } from "src/types";
import { PlayButton, PauseButton } from "src/icons";
import { formatTime, usePlayerContext, withoutUndefined } from "src/infra";
import { pick } from "lodash";

import * as vanillaStyles from "./AudioPlayer.css";
import styles from "./AudioPlayer.module.scss";

export function AudioPlayer(props: AudioPlayerProps) {
  const [state, setState] = usePlayerContext();

  const [audioProps, sourceProps] = splitProps(props, [
    "autoplay",
    "loop",
    "muted",
    "poster",
    "currentTime",
    "volume",
  ]);

  let audio: HTMLVideoElement;
  const [audioRef, setAudioRef] = createSignal<HTMLMediaElement>();

  createEffect(() => {
    setState({ props });
  });

  createEffect(() => {
    void sourceProps.languages;
    void sourceProps.tracks;
    void state.language;
    void state.quality;

    if (audio) {
      audio.load();

      untrack(() => {
        audio.currentTime = state.currentTime;
        if (state.paused) {
          audio.pause();
        } else {
          audio.play();
        }
      });
    }
  });

  function handleLoadedMetadata() {
    // Wavesurfer expects currentSrc to be set on media before it can successfully render anything and currentSrc is only
    // set when browser selects a source to play
    setAudioRef(audio);
  }

  function handlePlay() {
    setState({ paused: false });
  }

  function handlePause() {
    setState({ paused: true });
  }

  function handleVolumeChange() {
    setState({ muted: audio.muted, volume: audio.volume });
  }

  function handleDurationChange() {
    setState({ duration: audio.duration });
  }

  function handleTimeUpdate() {
    setState({ currentTime: audio.currentTime });
  }

  function handleTimelineChange(value: number) {
    setState({ currentTime: value });
  }

  function handleRateChange() {
    setState({ speed: audio.playbackRate.toString() });
  }

  function createPlayer($el: HTMLVideoElement) {
    audio = $el;

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("volumechange", handleVolumeChange);
    audio.addEventListener("durationchange", handleDurationChange);
    audio.addEventListener("ratechange", handleRateChange);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    sourceProps.onFinished &&
      audio.addEventListener("ended", sourceProps.onFinished);
  }

  onCleanup(() => {
    audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    audio.removeEventListener("play", handlePlay);
    audio.removeEventListener("pause", handlePause);
    audio.removeEventListener("volumechange", handleVolumeChange);
    audio.removeEventListener("durationchange", handleDurationChange);
    audio.removeEventListener("ratechange", handleRateChange);
    audio.removeEventListener("timeupdate", handleTimeUpdate);
    sourceProps.onFinished &&
      audio.addEventListener("ended", sourceProps.onFinished);
  });

  return (
    <div
      classList={{
        [styles.root]: true,
        dark: props.theme === "dark",
      }}
    >
      <video
        class={vanillaStyles.playback({
          type: "audio",
        })}
        preload="metadata"
        {...withoutUndefined(
          pick(audioProps, ["autoplay", "loop", "muted", "poster"])
        )}
        ref={createPlayer}
      >
        <For each={sourceProps.languages}>
          {(languageItem) => (
            <For each={languageItem.qualities}>
              {(qualityItem) => (
                <For each={qualityItem.sources}>
                  {(source) => (
                    <Show
                      when={
                        languageItem.label === state.language &&
                        (qualityItem.label === state.quality ||
                          state.quality === "Auto")
                      }
                      keyed
                    >
                      <source {...source} />
                    </Show>
                  )}
                </For>
              )}
            </For>
          )}
        </For>
        <For each={sourceProps.tracks}>{(track) => <track {...track} />}</For>
      </video>

      <div classList={{ [styles.overlay]: true }}>
        <div class={styles.overlayContent}>
          {props.poster && (
            <div
              class={styles.poster}
              onClick={() => (state.paused ? audio.play() : audio.pause())}
            >
              <img src={props.poster} role="presentation" />
            </div>
          )}

          <div class={styles.playButton}>
            <button
              // class={styles.IconButton}
              onClick={() => (state.paused ? audio.play() : audio.pause())}
            >
              {state.paused ? <PlayButton /> : <PauseButton />}
            </button>
          </div>

          {audioRef() && (
            <Waveform
              media={audioRef()!}
              class={styles.waveform}
              onTimeChange={handleTimelineChange}
            />
          )}

          <div class={styles.timestamps}>
            {`${formatTime(state.currentTime, state.duration)} / ${formatTime(
              state.duration,
              state.duration
            )}`}
          </div>

          <VolumeRocker
            class={styles.volumeRocker}
            level={state.volume}
            muted={state.muted}
            onLevelChange={(level) => audio && (audio.volume = level)}
            onMutedChange={(muted) => audio && (audio.muted = muted)}
          />
        </div>
      </div>
    </div>
  );
}
