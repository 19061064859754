export function VolumeMuteIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 16C22 13.64 20.64 11.6133 18.6667 10.6267V13.5733L21.9333 16.84C21.9733 16.5733 22 16.2933 22 16V16ZM25.3333 16C25.3333 17.2533 25.0667 18.4267 24.6133 19.52L26.6267 21.5333C27.5067 19.88 28 18 28 16C28 10.2933 24.0133 5.52 18.6667 4.30667V7.05333C22.52 8.2 25.3333 11.7733 25.3333 16ZM5.69333 4L4 5.69333L10.3067 12H4V20H9.33333L16 26.6667V17.6933L21.6667 23.36C20.7733 24.0533 19.7733 24.6 18.6667 24.9333V27.68C20.5067 27.2667 22.1733 26.4133 23.5867 25.2667L26.3067 28L28 26.3067L16 14.3067L5.69333 4ZM16 5.33333L13.2133 8.12L16 10.9067V5.33333Z"
        fill="currentColor"
      />
    </svg>
  );
}
