import { BasePlayerOptions } from "src/types";
import { BasePlayer } from "./BasePlayer";

export class WebPlayer extends BasePlayer {
  protected videoEl: HTMLVideoElement;

  private _isJustLoaded: boolean = true;
  public get isJustLoaded(): boolean {
    return this._isJustLoaded;
  }

  public get muted(): boolean {
    return this.videoEl?.muted ?? true;
  }

  public get currentTime(): number {
    return this.videoEl?.currentTime ?? 0;
  }

  public get duration(): number {
    return this.videoEl?.duration ?? 0;
  }

  public get volume(): number {
    return this.videoEl?.volume ?? 1;
  }

  public get speed(): number {
    return this.videoEl?.playbackRate ?? 1;
  }

  public get paused(): boolean {
    return this.videoEl?.paused ?? true;
  }

  public get src(): string {
    return this.videoEl?.src ?? "";
  }

  constructor(containerEl: HTMLDivElement, options?: BasePlayerOptions) {
    super(containerEl, options);

    const el = document.createElement("video");

    el.preload = "metadata";
    el.autoplay = (options?.autoplay && !options?.currentTime) || false;
    el.loop = options?.loop || false;
    el.muted = options?.muted || false;
    el.playsInline = true;

    this.containerEl.appendChild(el);
    this.videoEl = el;

    el.addEventListener("play", this.handlePlay);
    el.addEventListener("pause", this.handlePause);
    el.addEventListener("volumechange", this.handleVolumeChange);
    el.addEventListener("durationchange", this.handleDurationChange);
    el.addEventListener("ratechange", this.handleRateChange);
    el.addEventListener("timeupdate", this.handleTimeUpdate);
    el.addEventListener("waiting", this.handleWaiting);
    el.addEventListener("ended", this.handleEnded);
    el.addEventListener("error", this.handleError);
  }

  private handlePlay = () => {
    this.status = "playing";
    this.emit("play");
  };

  private handlePause = () => {
    this.status = "paused";
    this.emit("pause");
  };

  private handleVolumeChange = () => {
    this.emit("volumechange", this.videoEl.volume);
  };

  private handleDurationChange = () => {
    this.emit("durationchange", this.videoEl.duration);
  };

  private handleRateChange = () => {
    this.emit("ratechange", this.videoEl.playbackRate);
  };

  private handleTimeUpdate = () => {
    // MediaElement emits timeupdate event on load, which breaks the logic downhill -
    // behaviour seems useless, so we're just ignoring it
    if (this.videoEl.currentTime > 0 || !this.isJustLoaded) {
      this.status = "playing";
      this.emit("timeupdate", this.videoEl.currentTime);
      this._isJustLoaded = false;
    }
  };

  private handleWaiting = () => {
    this.status = "loading";
    this.emit("waiting");
  };

  private handleEnded = () => {
    this.emit("ended");
  };

  private handleError = () => {
    this.status = "error";
    this.emit("error", this.getErrorMessage(this.videoEl.error?.code ?? 0));
  };

  protected getErrorMessage(code: number) {
    const ERROR_MESSAGES: Record<number, string> = {
      [MediaError.MEDIA_ERR_ABORTED]:
        "The fetching process for the media resource was aborted by the user agent at the user's request.",
      [MediaError.MEDIA_ERR_DECODE]:
        "An error of some description occurred while decoding the media resource, after the resource was established to be usable.",
      [MediaError.MEDIA_ERR_NETWORK]:
        "An error of some description occurred while establishing the media resource, due to a network error.",
      [MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED]:
        "The media resource indicated by the src attribute was not suitable.",
    };

    return ERROR_MESSAGES[code]
      ? ERROR_MESSAGES[code]
      : `Unknown error occurred.`;
  }

  loadSource(sourceUrl: string): Promise<void> {
    if (this.status === "loading" && sourceUrl === this.src) {
      console.error(`${sourceUrl} is already loading`);
      return Promise.resolve();
    }

    this.status = "loading";

    return this.exec(() =>
      new Promise<void>((resolve, reject) => {
        this.videoEl.addEventListener(
          "loadeddata",
          () => {
            this.status = "paused";
            resolve();
          },
          { once: true }
        );
        this.videoEl.addEventListener("error", reject, { once: true });
        this.videoEl.src = sourceUrl;
        this.videoEl.load();
      }).then(async () => {
        await this.setVolume(this.options?.volume ?? 1);
        await this.setSpeed(this.options?.playbackRate ?? 1);

        if (!!this.options?.currentTime) {
          await this.seekTo(this.options?.currentTime ?? 0);
        }
      })
    );
  }

  play(): Promise<void> {
    return this.exec(() => this.videoEl.play());
  }

  pause(): Promise<void> {
    return this.exec(() => {
      this.videoEl.pause();
      return Promise.resolve();
    });
  }

  mute(): Promise<void> {
    return this.exec(() => {
      this.videoEl.muted = true;
      return Promise.resolve();
    });
  }

  unmute(): Promise<void> {
    return this.exec(() => {
      this.videoEl.muted = false;
      return Promise.resolve();
    });
  }

  seekTo(time: number): Promise<void> {
    return this.exec(() => {
      this.videoEl.currentTime = time;
      return Promise.resolve();
    });
  }

  setVolume(volume: number): Promise<void> {
    return this.exec(() => {
      this.videoEl.volume = volume;
      return Promise.resolve();
    });
  }

  setSpeed(speed: number): Promise<void> {
    return this.exec(() => {
      this.videoEl.playbackRate = speed;
      return Promise.resolve();
    });
  }

  enterFullscreen(): Promise<void> {
    return this.exec(async () => {
      if ("requestFullscreen" in this.videoEl) {
        this.videoEl.requestFullscreen();
      } else if ("webkitEnterFullscreen" in this.videoEl) {
        // @ts-expect-error - webkitEnterFullscreen is not in the types
        this.videoEl.webkitEnterFullscreen();
      }
    });
  }

  exitFullscreen(): Promise<void> {
    return this.exec(() => document.exitFullscreen());
  }

  isFullscreen(): boolean {
    return !!document.fullscreenElement;
  }

  destroy(): Promise<void> {
    super.destroy();

    this.removeAllListeners();

    this.videoEl.removeEventListener("play", this.handlePlay);
    this.videoEl.removeEventListener("pause", this.handlePause);
    this.videoEl.removeEventListener("volumechange", this.handleVolumeChange);
    this.videoEl.removeEventListener(
      "durationchange",
      this.handleDurationChange
    );
    this.videoEl.removeEventListener("ratechange", this.handleRateChange);
    this.videoEl.removeEventListener("timeupdate", this.handleTimeUpdate);
    this.videoEl.removeEventListener("waiting", this.handleWaiting);
    this.videoEl.removeEventListener("ended", this.handleEnded);
    this.videoEl.removeEventListener("error", this.handleError);

    this.videoEl.parentNode?.removeChild(this.videoEl);
    // @ts-ignore
    this.videoEl = null;
    return Promise.resolve();
  }
}
