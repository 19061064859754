// @ts-nocheck
import { BasePlayer, getExtension, loadScript, waitFor } from "src/infra";
import { BasePlayerOptions } from "src/types";

// the folllowing is useful for development, but TS fails to compile it
//import "@types/chromecast-caf-sender";

export async function loadCastSDK() {
  const CHROMECAST_JS_URL =
    "//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";

  // @ts-ignore
  if (!window["cast"]) {
    const isAvailable = await loadScript(
      CHROMECAST_JS_URL,
      {},
      "__onGCastApiAvailable"
    );

    if (!isAvailable) {
      throw new Error("Chromecast SDK not available");
    }
    // configure CastContext singleton
    cast.framework.CastContext.getInstance().setOptions({
      receiverApplicationId:
        import.meta.env.VITE_CAST_APP_ID ||
        chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
    });
  }
}

const EXT_TO_MIME: Record<string, string> = {
  mp4: "video/mp4",
  m4v: "video/mp4",
  mkv: "video/x-matroska",
  webm: "video/webm",
  mov: "video/quicktime",
  avi: "video/x-msvideo",
  wmv: "video/x-ms-wmv",
  flv: "video/x-flv",
  "3gp": "video/3gpp",
  "3g2": "video/3gpp2",
  m3u8: "application/vnd.apple.mpegurl", // HLS Streaming
  mpd: "application/dash+xml", // MPEG-DASH Streaming
  ism: "application/vnd.ms-sstr+xml", // Microsoft Smooth Streaming
};

export class ChromecastPlayer extends BasePlayer {
  private player: cast.framework.RemotePlayer | null = null;
  private controller: cast.framework.RemotePlayerController | null = null;

  public get muted(): boolean {
    return this.player?.isMuted ?? true;
  }

  public get currentTime(): number {
    return this.player?.currentTime ?? 0;
  }

  public get duration(): number {
    return this.player?.duration ?? 0;
  }

  public get volume(): number {
    return this.player?.volumeLevel ?? 1;
  }

  public get speed(): number {
    const castSession =
      cast.framework.CastContext.getInstance().getCurrentSession();
    const media = castSession?.getMediaSession();
    return media?.playbackRate ?? 1;
  }

  public get paused(): boolean {
    return this.player?.isPaused ?? true;
  }

  private _src: string = "";
  public get src(): string {
    return this._src;
  }

  constructor(containerEl: HTMLDivElement, options?: BasePlayerOptions) {
    super(containerEl, options);

    if (options.poster) {
      const img = document.createElement("img");
      img.src = options.poster;
      img.alt = options.title ?? "";
      Object.assign(img.style, {
        width: "100%",
        height: "100%",
        objectFit: "contain",
        objectPosition: "center",
      });
      this.containerEl.appendChild(img);
    }
  }

  private handlePauseChange = () => {
    if (this.paused) {
      this.status = "paused";
      this.emit("pause");
    } else {
      this.status = "playing";
      this.emit("play");
    }
  };

  private handleVolumeChange = () => {
    if (this.muted) {
      this.emit("volumechange", 0);
    } else {
      this.emit("volumechange", this.volume);
    }
  };

  private handleTimeChange = (
    data: cast.framework.RemotePlayerChangedEvent
  ) => {
    this.emit("timeupdate", data.value);
  };

  private handleStateChange = (
    data: cast.framework.RemotePlayerChangedEvent
  ) => {
    this.emit("status", (this.status = this.getStatus(data.value)));
  };

  private handleCastStateChanged = (
    event: cast.framework.CastStateEventData
  ) => {
    // TODO: remove when troubleshooting is finished
    switch (event.sessionState) {
      case cast.framework.SessionState.SESSION_STARTED:
        console.log("CastContext: CastSession started");
        break;
      case cast.framework.SessionState.SESSION_RESUMED:
        console.log("CastContext: CastSession resumed");
        break;
      case cast.framework.SessionState.SESSION_ENDED:
        console.log("CastContext: CastSession disconnected");
        break;
    }
  };

  private attachEvents(ctrl: any) {
    ctrl.addEventListener(
      cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      this.handlePauseChange
    );
    ctrl.addEventListener(
      cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
      this.handleVolumeChange
    );
    ctrl.addEventListener(
      cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
      this.handleVolumeChange
    );
    ctrl.addEventListener(
      cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      this.handleTimeChange
    );
    ctrl.addEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      this.handleStateChange
    );
  }

  private getStatus(status: chrome.cast.media.PlayerState) {
    const STATUSES = {
      [chrome.cast.media.PlayerState.IDLE]: "idle",
      [chrome.cast.media.PlayerState.PAUSED]: "paused",
      [chrome.cast.media.PlayerState.PLAYING]: "playing",
      [chrome.cast.media.PlayerState.BUFFERING]: "loading",
    };
    return STATUSES[status];
  }

  private getErrorMessage(code: string) {
    const ERROR_MESSAGES: Record<string, string> = {
      [chrome.cast.ErrorCode
        .API_NOT_INITIALIZED]: `The API is not initialized.`,
      [chrome.cast.ErrorCode.CANCEL]: `The operation was canceled by the user.`,
      [chrome.cast.ErrorCode
        .CHANNEL_ERROR]: `A channel to the receiver is not available.`,
      [chrome.cast.ErrorCode
        .EXTENSION_MISSING]: `The Cast extension is not available.`,
      [chrome.cast.ErrorCode
        .INVALID_PARAMETER]: `The parameters to the operation were not valid.`,
      [chrome.cast.ErrorCode
        .RECEIVER_UNAVAILABLE]: `No receiver was compatible with the session request.`,
      [chrome.cast.ErrorCode
        .SESSION_ERROR]: `A session could not be created, or a session was invalid.`,
      [chrome.cast.ErrorCode.TIMEOUT]: `The operation timed out.`,
    };

    return ERROR_MESSAGES[code]
      ? ERROR_MESSAGES[code]
      : `Unknown error occurred.`;
  }

  private syncPlayerState(currentMedia: chrome.cast.media.Media) {
    if (!this.player || !currentMedia) return;

    this.player.currentTime = currentMedia.currentTime;
    this.player.isPaused =
      currentMedia.playerState !== chrome.cast.media.PlayerState.PLAYING;
    this.player.volumeLevel = currentMedia.volume.level;
    this.player.isMuted = currentMedia.volume.muted;

    this.status = this.getStatus(currentMedia.playerState);
  }

  loadSource(sourceUrl: string): Promise<void> {
    const _loadSource = () =>
      loadCastSDK()
        .then(() => {
          this.player = new cast.framework.RemotePlayer();
          this.controller = new cast.framework.RemotePlayerController(
            this.player
          );

          const context = cast.framework.CastContext.getInstance();

          context.addEventListener(
            cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
            this.handleCastStateChanged
          );

          const castSession = context.getCurrentSession();
          const currentMedia = castSession.getMediaSession();

          if (
            currentMedia?.media?.contentId === sourceUrl &&
            this.options?.playbackRate === this.speed
          ) {
            // the desired media is already playing
            // Optionally, sync player state (e.g., currentTime, isPaused) with currentMedia
            this.syncPlayerState(currentMedia);
          } else {
            const mediaInfo = new chrome.cast.media.MediaInfo(
              sourceUrl,
              EXT_TO_MIME[getExtension(sourceUrl)]
            );
            mediaInfo.metadata = new chrome.cast.media.GenericMediaMetadata();
            mediaInfo.metadata.metadataType =
              chrome.cast.media.MetadataType.GENERIC;
            this.options?.title &&
              (mediaInfo.metadata.title = this.options.title);
            this.options?.poster &&
              (mediaInfo.metadata.images = [{ url: this.options.poster }]);

            const request = new chrome.cast.media.LoadRequest(mediaInfo);
            // autoplay is on by default, but it never reports back initial state, so we better trigger it ourselves
            request.autoplay = false;
            request.currentTime = this.options?.currentTime ?? 0;
            request.playbackRate = this.options?.playbackRate ?? 1;

            this.status = "loading";

            return castSession
              ?.loadMedia(request)
              .catch((errorCode: chrome.cast.ErrorCode) => {
                this.status = "error";
                this.emit("error", this.getErrorMessage(errorCode));
              });
          }
        })
        .then(() => {
          // attach events only after media is loaded or state synced, 'cause Cast SDK emits irrelevant
          // play/pause events right after loadMedia call
          this.attachEvents(this.controller);

          // couldn't find a way to supply initial volume in LoadRequest
          return this.setVolume(this.options?.volume ?? 1);
        });

    if (this.status === "loading" && sourceUrl === this.src) {
      console.error(`${sourceUrl} is already loading`);
      return Promise.resolve();
    }

    this.status = "loading";
    this._src = sourceUrl;

    return this.exec(_loadSource);
  }

  play(): Promise<void> {
    return this.exec(() => {
      if (this.paused && this.player.isMediaLoaded) {
        this.controller?.playOrPause();
      }
      return waitFor(() => !this.paused);
    });
  }

  pause(): Promise<void> {
    return this.exec(() => {
      if (!this.paused) {
        this.controller?.playOrPause();
      }
      return waitFor(() => this.paused);
    });
  }

  mute(): Promise<void> {
    return this.exec(() => {
      if (!this.muted) {
        this.controller?.muteOrUnmute();
      }
      return waitFor(() => this.muted);
    });
  }

  unmute(): Promise<void> {
    return this.exec(() => {
      if (this.muted) {
        this.controller?.muteOrUnmute();
      }
      return waitFor(() => !this.muted);
    });
  }

  seekTo(time: number): Promise<void> {
    return this.exec(() => {
      if (this.player) {
        this.player.currentTime = time;
        this.controller?.seek();
      }
      return Promise.resolve();
    });
  }

  setVolume(volume: number): Promise<void> {
    return this.exec(() => {
      if (this.player) {
        this.player.volumeLevel = volume;
        this.controller?.setVolumeLevel();
      }
      return Promise.resolve();
    });
  }

  setSpeed(speed: number): Promise<void> {
    return this.exec(() => {
      //! it seems the only way to change the speed is to reload the media
      this.emit("ratechange", speed);
      return Promise.resolve();
    });
  }

  destroy() {
    super.destroy();

    this.removeAllListeners();

    const context = cast.framework.CastContext.getInstance();
    context.removeEventListener(
      cast.framework.CastContextEventType.SESSION_STATE_CHANGED,
      this.handleCastStateChanged
    );

    this.controller?.removeEventListener(
      cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
      this.handlePauseChange
    );
    this.controller?.removeEventListener(
      cast.framework.RemotePlayerEventType.IS_MUTED_CHANGED,
      this.handleVolumeChange
    );
    this.controller?.removeEventListener(
      cast.framework.RemotePlayerEventType.VOLUME_LEVEL_CHANGED,
      this.handleVolumeChange
    );
    this.controller?.addEventListener(
      cast.framework.RemotePlayerEventType.CURRENT_TIME_CHANGED,
      this.handleTimeChange
    );
    this.controller?.removeEventListener(
      cast.framework.RemotePlayerEventType.PLAYER_STATE_CHANGED,
      this.handleStateChange
    );

    this.player = null;
    this.controller = null;

    this.containerEl.innerHTML = "";

    const castSession = context.getCurrentSession();
    const currentMedia = castSession.getMediaSession();
    return currentMedia?.stop();
  }
}
