import { fill, root } from "./icons.css";

export const PauseButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    class={root}
  >
    <g fill="none" stroke="#ce2027" stroke-width={2}>
      <rect width={48} height={48} stroke="none" rx={8} />
      <rect width={46} height={46} x={1} y={1} rx={7} />
    </g>
    <rect
      width={48}
      height={48}

      class={fill({
        default: "transparent",
        hover: "active"
      })}
      rx={8}
    />
    <rect
      width={3}
      height={16}
      class={fill({
        default: "active",
        hover: "white"
      })}
      rx={1}
      transform="translate(17 16)"
    />
    <rect
      width={3}
      height={16}
      class={fill({
        default: "active",
        hover: "white"
      })}
      rx={1}
      transform="translate(28 16)"
    />
  </svg>
)

