import { usePlayerContext } from "../../infra/PlayerContext";
import { Minus15Button, PauseButton, PlayButton, Plus15Button } from "src/icons";

import styles from "./PlaybackControls.module.scss";
import iconStyles from "../IconButton/IconButton.module.scss";

export interface PlaybackControlsProps {
  hideShiftControls?: boolean;
  onTimeChange: (time: number) => void;
  onPlayPause: (isPaused: boolean) => void;
  disabled?: boolean;
}

export function PlaybackControls(props: PlaybackControlsProps) {
  const [state] = usePlayerContext();

  return (
    <div
      classList={{
        [styles.playControls]: true,
      }}
    >
      {!props.hideShiftControls && (
        <button
          disabled={props.disabled}
          class={iconStyles.simpleIconButton}
          onClick={() =>
            props.onTimeChange(
              Math.floor(Math.max(0, Math.floor(state.currentTime) - 15))
            )
          }
        >
          <Minus15Button />
        </button>
      )}

      <button
        disabled={props.disabled}
        class={iconStyles.IconButton}
        onClick={() => props.onPlayPause(!state.paused)}
      >
        {state.paused ? <PlayButton /> : <PauseButton />}
      </button>

      {!props.hideShiftControls && (
        <button
          disabled={props.disabled}
          class={iconStyles.simpleIconButton}
          onClick={() =>
            props.onTimeChange(
              Math.floor(
                Math.min(state.duration, Math.floor(state.currentTime) + 15)
              )
            )
          }
        >
          <Plus15Button />
        </button>
      )}
    </div>
  );
}
