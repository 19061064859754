export const SettingsActiveButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <g fill="none">
      <path
        fill="currentColor"
        d="M27 37.999h-6l-.6-3.6a11.086 11.086 0 0 1-1.206-.5l-2.972 2.125-4.244-4.243 2.126-2.973a10.989 10.989 0 0 1-.5-1.206l-3.6-.6v-6l3.6-.6a10.8 10.8 0 0 1 .5-1.2l-2.126-2.972 4.244-4.244 2.972 2.123a10.924 10.924 0 0 1 1.206-.5l.6-3.6h6l.6 3.6a11.067 11.067 0 0 1 1.206.5l2.97-2.121 4.243 4.241-2.122 2.972a10.554 10.554 0 0 1 .5 1.2l3.6.6v6l-3.6.6a10.3 10.3 0 0 1-.5 1.2l2.122 2.975-4.243 4.243-2.972-2.125a11.267 11.267 0 0 1-1.2.5l-.6 3.6Zm-3-19a5.006 5.006 0 0 0-5 5 5.006 5.006 0 0 0 5 5 5.006 5.006 0 0 0 5-5 5.006 5.006 0 0 0-5-5Z"
      />
    </g>
  </svg>
);
