// the folllowing is useful for development, but TS fails to compile it
// import "@types/chromecast-caf-sender";
// @ts-nocheck

import { ButtonProps } from "src/components";
import { loadCastSDK } from "src/infra";
import { createEffect, createSignal, onCleanup, splitProps } from "solid-js";

type CastLauncherButtonProps = ButtonProps & {
  onCastStateChanged?: (isConnected: boolean) => void;
};

export const CastLauncherButton = (props: CastLauncherButtonProps) => {
  const [castProps, buttonProps] = splitProps(props, ["onCastStateChanged"]);

  const [isSDKLoaded, setIsSDKLoaded] = createSignal(false);

  // CAST_STATE_CHANGED is fired continuously (at least when cast is disconnected with
  // NO_DEVICES_AVAILABLE and NOT_CONNECTED events), so we need to keep track of the
  // current cast state and avoid unnecessary calls to onCastStateChanged
  const [castState, setCastState] = createSignal("NOT_CONNECTED");

  const handleCastStateChanged = (event: cast.framework.CastStateEventData) => {
    // we are only interested in CONNECTED and NOT_CONNECTED events
    if (
      ["CONNECTED", "NOT_CONNECTED"].includes(event.castState) &&
      event.castState !== castState()
    ) {
      setCastState(event.castState);
    }
  };

  createEffect(() => {
    // emit only when castState changes
    castProps.onCastStateChanged?.(castState() === "CONNECTED");
  });

  loadCastSDK()
    .then(() => {
      setIsSDKLoaded(true);

      const castContext = cast.framework.CastContext.getInstance();
      castContext.addEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        handleCastStateChanged
      );
    })
    .catch((e) => {
      console.error(`Failed to load cast SDK: ${e.message}`);
    });

  onCleanup(() => {
    if (isSDKLoaded()) {
      const castContext = cast.framework.CastContext.getInstance();
      castContext.removeEventListener(
        cast.framework.CastContextEventType.CAST_STATE_CHANGED,
        handleCastStateChanged
      );
    }
  });

  return (
    <>
      {isSDKLoaded() && (
        // @ts-ignore
        <google-cast-launcher {...buttonProps} />
      )}
    </>
  );
};
