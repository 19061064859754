import { loadScript } from "src/infra";
import { WebPlayer } from "./WebPlayer";

declare var Hls: any;

const HLS_JS_URL = "//cdn.jsdelivr.net/npm/hls.js@1";
const MAX_RETRIES = 3;

export class HlsPlayer extends WebPlayer {
  private hls: any = null;

  private async initHlsJS() {
    // @ts-ignore
    if (!window["Hls"]) {
      await loadScript(HLS_JS_URL);
    }

    // @ts-ignore
    if (!window["Hls"] || !Hls.isSupported()) {
      throw new Error("Hls is not supported");
    }

    const hls = new Hls();
    hls.attachMedia(this.videoEl);
    return (this.hls = hls);
  }

  loadSource(sourceUrl: string): Promise<void> {
    this.status = "loading";

    const _loadSource = (retryCount: number = 0) =>
      this.initHlsJS()
        .catch((err) => {
          this.status = "error";
          this.emit("error", err.message);
        })
        .then(
          (hls) =>
            new Promise<void>(async (resolve, reject) => {
              hls.once(Hls.Events.ERROR, (_: any, data: any) => {
                // HLS stream often fails to load on the first attempt, but succeeds on a consequent one
                if (data.fatal && retryCount < MAX_RETRIES) {
                  console.warn(
                    `Couldn't load the source - retrying. Attempt: ${retryCount}`
                  );
                  resolve(_loadSource(retryCount + 1));
                } else {
                  this.status = "error";
                  this.emit("error", data.details);
                  reject();
                }
              });

              hls.once(Hls.Events.MANIFEST_PARSED, () => {
                this.status = "paused";
                resolve();
              });

              hls.loadSource(sourceUrl);
            })
        );

    this.status = "loading";

    return this.exec(() => _loadSource()).then(async () => {
      await this.setVolume(this.options?.volume ?? 1);
    });
  }

  destroy(): Promise<void> {
    super.destroy();

    if (this.hls) {
      this.hls.destroy();
    }

    return Promise.resolve();
  }
}
