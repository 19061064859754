import { isFinished } from "src/infra";
import { BasePlayerOptions } from "src/types";
import { EventEmitter } from "events";

export type PlayerStatus = "loading" | "playing" | "paused" | "idle" | "error";

export abstract class BasePlayer extends EventEmitter {
  private _status: PlayerStatus = "idle";
  public get status(): PlayerStatus {
    return this._status;
  }
  public set status(value: PlayerStatus) {
    if (this._status !== value) {
      this._status = value;
      this.emit("status", value);
    }
  }

  protected inProgress: Promise<void> | null = null;

  private _isDestroyed: boolean = false;

  constructor(
    protected containerEl: HTMLDivElement,
    protected options?: BasePlayerOptions
  ) {
    super();
  }

  // all of the methods are async 'cause this class is meant to be a base class for Hls and also a Chromecast players
  abstract loadSource(url: string): Promise<void>;
  abstract play(): Promise<void>;
  abstract pause(): Promise<void>;
  abstract seekTo(time: number): Promise<void>;
  abstract setVolume(volume: number): Promise<void>;

  destroy(): void {
    this._isDestroyed = true;
  }

  protected exec(op: () => Promise<any>) {
    if (this._isDestroyed) {
      // console.error("Player is destroyed");
      // basically this will resolve any chained ops as well without doing anything
      return Promise.resolve();
    }

    return (this.inProgress =
      !!this.inProgress && !isFinished(this.inProgress)
        ? this.inProgress.then(op, (err) => {
            console.error(err);
            return op();
          })
        : op());
  }
}
