import styles from "./VolumeRocker.module.scss";
import { VolumeHighIcon, VolumeLowIcon, VolumeMuteIcon } from "src/icons";
import c from "classnames";
import { VolumeRockerProps } from "./types";

export function MobileVolumeRocker(props: VolumeRockerProps) {
  const getVolumeIcon = () => {
    if (props.level === 0 || props.muted) {
      return <VolumeMuteIcon />;
    } else if (props.level <= 0.5) {
      return <VolumeLowIcon />;
    } else {
      return <VolumeHighIcon />;
    }
  };

  return (
    <div class={c(styles.volumeRocker, props.class)}>
      <button
        class={styles.volumeButton}
        type="button"
        onClick={() => {
          if (props.level === 0) {
            props.onLevelChange(0.5);
          } else {
            props.onMutedChange(!props.muted);
          }
        }}
      >
        {getVolumeIcon()}
      </button>
    </div>
  );
}
