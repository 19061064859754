import {
  createSignal,
  mergeProps,
  onCleanup,
  onMount,
  splitProps,
} from "solid-js";
import WaveSurfer, { WaveSurferOptions } from "wavesurfer.js";
import c from "classnames";
import { Progress } from "@kobalte/core";

import styles from "./Waveform.module.scss";

export interface WaveformProps extends Omit<WaveSurferOptions, "container"> {
  class?: string;
  onLoadProgress?: (progress: number) => void;
  onTimeChange?: (time: number) => void;
}

export const Waveform = (originalProps: WaveformProps) => {
  const [{ class: className }, props] = splitProps(
    mergeProps(
      {
        height: 70,
        waveColor: "rgb(156 163 175)",
        progressColor: "#CE2027",
        barWidth: 10,
        barGap: 3,
        barRadius: 1,
        barAlign: "bottom" as "top" | "bottom",
        cursorWidth: 0,
      },
      originalProps
    ),
    ["class"]
  );

  let waveform: WaveSurfer;
  let container: HTMLDivElement;

  const [loadingProgress, setLoadingProgress] = createSignal(0);

  onMount(() => {
    waveform = WaveSurfer.create({
      container,
      media: props.media,
      ...props,
    });

    waveform.on("loading", setLoadingProgress);
    props.onTimeChange && waveform.on("timeupdate", props.onTimeChange);
  });

  onCleanup(() => {
    if (waveform) {
      waveform.un("loading", setLoadingProgress);
      props.onTimeChange && waveform.un("timeupdate", props.onTimeChange);
      waveform.destroy();
    }
  });

  return (
    <div class={c(styles.container, className)}>
      <div
        ref={($el) => {
          container = $el;
        }}
        class={styles.waveform}
      />
      {loadingProgress() < 100 && (
        <Progress.Root
          class={styles.progressBar}
          minValue={0}
          maxValue={100}
          value={loadingProgress()}
        >
          <Progress.Track class={styles.progressTrack}>
            <Progress.Fill class={styles.progressFill} />
          </Progress.Track>
        </Progress.Root>
      )}
    </div>
  );
};
