export function VolumeHighIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 12V20H9.33333L16 26.6666V5.33331L9.33333 12H4ZM22 16C22 13.64 20.64 11.6133 18.6667 10.6266V21.36C20.64 20.3866 22 18.36 22 16ZM18.6667 4.30664V7.05331C22.52 8.19997 25.3333 11.7733 25.3333 16C25.3333 20.2266 22.52 23.8 18.6667 24.9466V27.6933C24.0133 26.48 28 21.7066 28 16C28 10.2933 24.0133 5.51997 18.6667 4.30664Z"
        fill="currentColor"
      />
    </svg>
  );
}
