export const Plus15Button = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <g fill="currentColor" opacity={0.5}>
      <text
        font-family="gpbMtavruli-Regular, gpbMtavruli"
        font-size="12"
        transform="translate(15 16)"
      >
        <tspan x={3} y={12}>
          {"15"}
        </tspan>
      </text>
      <g>
        <path d="M24 40.001a16 16 0 0 0 16-16c0-.176 0-.34-.008-.5h-2c.008.156.008.324.008.5a14 14 0 0 1-23.9 9.9 14 14 0 0 1 9.9-23.9 14.057 14.057 0 0 1 3.846.533l.5-1.937A16 16 0 1 0 24 40.001Z" />
        <g>
          <path d="m23.637 15.428-1.414-1.414 6.364-6.364L30 9.064z" />
          <path d="M22.223 4.114 23.637 2.7l6.364 6.364-1.414 1.414z" />
        </g>
      </g>
    </g>
  </svg>
);
