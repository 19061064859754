export const SPEED_OPTIONS = [
  {
    label: "0.25x",
    value: "0.25",
  },
  {
    label: "0.50x",
    value: "0.5",
  },
  {
    label: "0.75x",
    value: "0.75",
  },
  {
    label: "1x",
    value: "1",
  },
  {
    label: "1.25x",
    value: "1.25",
  },
  {
    label: "1.50x",
    value: "1.5",
  },
  {
    label: "1.75x",
    value: "1.75",
  },
  {
    label: "2x",
    value: "2",
  },
];
