export const Minus15Button = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <g fill="currentColor" opacity={0.5}>
      <text
        font-family="gpbMtavruli-Regular, gpbMtavruli"
        font-size="12"
        transform="translate(15 16)"
      >
        <tspan x={3} y={12}>
          {"15"}
        </tspan>
      </text>
      <g>
        <path d="M24 40a15.889 15.889 0 0 1-11.312-4.687A15.9 15.9 0 0 1 8 24c0-.176 0-.34.008-.5h2c-.006.157-.009.324-.009.5a13.9 13.9 0 0 0 4.1 9.9 13.91 13.91 0 0 0 9.9 4.1 13.909 13.909 0 0 0 9.9-4.1 13.9 13.9 0 0 0 4.1-9.9 13.906 13.906 0 0 0-4.1-9.9 13.917 13.917 0 0 0-9.9-4.1 14.057 14.057 0 0 0-3.846.533l-.5-1.937a16.013 16.013 0 0 1 4.346-.6 15.9 15.9 0 0 1 11.315 4.687A15.9 15.9 0 0 1 40 24a15.9 15.9 0 0 1-4.686 11.313A15.9 15.9 0 0 1 24 40Z" />
        <g>
          <path d="m18 9.064 1.414-1.414 6.364 6.364-1.414 1.414z" />
          <path d="M19.414 10.478 18 9.064 24.364 2.7l1.414 1.414z" />
        </g>
      </g>
    </g>
  </svg>
);
