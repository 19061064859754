export const EnterFullScreenButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48}>
    <g fill="currentColor">
      <path d="M10 10h2v8h-2z" />
      <path d="M38 38h-2v-8h2z" />
      <path d="M38 10v2h-8v-2z" />
      <path d="M10 38v-2h8v2z" />
      <path d="M10 12v-2h8v2z" />
      <path d="M38 36v2h-8v-2z" />
      <path d="M36 10h2v8h-2z" />
      <path d="M12 38h-2v-8h2z" />
    </g>
  </svg>
);
