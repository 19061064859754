import { fill, root } from "./icons.css";

export const PlayButton = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} class={root}>
    <g fill="none" stroke="#ce2027" stroke-width={2}>
      <rect width={48} height={48} stroke="none" rx={8} />
      <rect width={46} height={46} x={1} y={1} rx={7} />
    </g>
    <rect
      width={48}
      height={48}
      class={fill({
        default: "transparent",
        hover: "active",
      })}
      rx={8}
    />
    <path
      class={fill({
        default: "active",
        hover: "white",
      })}
      d="M32.593 22.332a1.908 1.908 0 0 1 0 3.335l-11.333 6.3a1.908 1.908 0 0 1-2.834-1.674v-12.59a1.908 1.908 0 0 1 2.834-1.667Z"
    />
  </svg>
);
