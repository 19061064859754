import { createEffect, createSignal, onCleanup, onMount } from "solid-js";
import { clamp } from "src/infra";
import styles from "./Timeline.module.scss";
import { createElementBounds } from "@solid-primitives/bounds";
import { createMemo } from "solid-js";
import { debounce } from "lodash";

export interface TimelineProps {
  duration: number;
  currentTime: number;
  interactive?: boolean;
  onChange?: (currentTime: number) => void;
}

export function Timeline(props: TimelineProps) {
  const [localCurrentTime, setLocalCurrentTime] = createSignal(
    props.currentTime
  );
  const [mouseDown, setMouseDown] = createSignal(false);
  let [trackRef, setTrackRef] = createSignal<HTMLDivElement>();
  const trackRect = createElementBounds(trackRef);

  const update = (clientX: number) => {
    const w = trackRect.width!;
    const x = clientX - trackRect.left!;
    const currentTime = clamp(0, (x / w) * props.duration, props.duration);
    // we have local state to support smooth dragging of the handle
    setLocalCurrentTime(currentTime);
    if (mouseDown()) {
      handleChange(currentTime);
    } else {
      // if the user clicks on the timeline, we want to bypass debounce delay
      props.onChange?.(currentTime);
    }
  };

  const handleChange = debounce((currentTime: number) => {
    props.onChange?.(currentTime);
  }, 100);

  function handleMouseMove(e: MouseEvent): void {
    if (mouseDown()) {
      update(e.clientX);
    }
  }

  function handleTouchMove(e: TouchEvent): void {
    if (mouseDown()) {
      update(e.touches[0]!.clientX);
    }
  }

  function handleMouseDown(e: MouseEvent): void {
    setMouseDown(true);
    update(e.clientX);
  }

  function handleMouseUp(e: MouseEvent): void {
    if (mouseDown()) {
      setMouseDown(false);
      update(e.clientX);
    }
  }

  function handleTouchStart(e: TouchEvent): void {
    if (e.touches.length !== 1) return;

    setMouseDown(true);
    update(e.touches[0].clientX);
  }

  function handleTouchEnd(_e: TouchEvent): void {
    setMouseDown(false);
  }

  function handleMouseLeave(): void {
    setMouseDown(false);
  }

  function handleTouchCancel(): void {
    setMouseDown(false);
  }

  createEffect(() => {
    if (!mouseDown()) {
      setLocalCurrentTime(props.currentTime);
    }
  });

  const width = createMemo(() => {
    return props.interactive
      ? (trackRect.width! * localCurrentTime()) / props.duration
      : trackRect.width!;
  });

  onMount(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mouseleave", handleMouseLeave);
    document.addEventListener("touchend", handleTouchEnd);
    document.addEventListener("touchmove", handleTouchMove);
    document.addEventListener("touchcancel", handleTouchCancel);
  });

  onCleanup(() => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mouseleave", handleMouseLeave);
    document.removeEventListener("touchend", handleTouchEnd);
    document.removeEventListener("touchmove", handleTouchMove);
    document.removeEventListener("touchcancel", handleTouchCancel);
  });

  return (
    <div
      classList={{
        [styles.interactive]: props.interactive,
        [styles.track]: true,
      }}
      ref={setTrackRef}
      onMouseDown={props.interactive ? handleMouseDown : undefined}
      onTouchStart={props.interactive ? handleTouchStart : undefined}
    >
      {trackRect.width && (
        <>
          <div
            class={styles.trackFilled}
            style={{
              width: width() + "px",
            }}
          />
          {props.interactive && (
            <div
              class={styles.thumb}
              style={{
                left: width() - 3 + "px",
              }}
            />
          )}
        </>
      )}
    </div>
  );
}
