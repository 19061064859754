import {
  createEffect,
  splitProps,
  onCleanup,
  mergeProps,
  createMemo,
  createSignal,
} from "solid-js";
import { Timeline, VolumeRocker, PlaybackControls, SettingsControls } from "..";
import * as vanillaStyles from "./VideoPlayer.css";
import styles from "./VideoPlayer.module.scss";
import { PlayButton, PauseButton, Spinner, ShareButton } from "src/icons";
import {
  useResponsive,
  formatTime,
  usePlayerContext,
  WebPlayer,
  HlsPlayer,
  PlayerStatus,
  isHlsSource,
  useCurrentSource,
  ChromecastPlayer,
  PlayerStateKeys,
} from "src/infra";
import { VideoPlayerProps } from "src/types";
import { createIdleTimer } from "@solid-primitives/idle";
import { merge } from "lodash";
import { CopiedButton } from "src/icons/CopiedButton";
import { BackButton } from "src/icons/BackButton";

export function VideoPlayer(propsOriginal: VideoPlayerProps) {
  const [state, setState] = usePlayerContext();
  const props = mergeProps(
    { controls: true, hls: false, chromeCast: false } as const,
    propsOriginal
  );
  const [videoProps, sourceProps] = splitProps(props, [
    "autoplay",
    "loop",
    "muted",
    "poster",
    "currentTime",
    "volume",
  ]);

  const { isUnrelatedSource, currentSource, currentLanguage } =
    useCurrentSource(sourceProps.languages);

  let solidPlayer: HTMLDivElement;
  let $playerEl: HTMLDivElement;
  let player: WebPlayer | HlsPlayer | ChromecastPlayer;

  const { isMobile, isDesktop } = useResponsive();

  const isLiveMemo = createMemo(
    () => sourceProps.hls && isHlsSource(currentSource())
  );

  createEffect(() => {
    setState({ props });
  });

  createEffect(() => {
    // tracking every source change, except initial one and resetting the player when it happens
    const src = currentSource()?.src;
    if (src && src !== player?.src) {
      resetPlayer($playerEl);
    }
  });

  createEffect((casting) => {
    if (casting !== state.casting) {
      resetPlayer($playerEl);
    }
    return state.casting;
  }, state.casting);

  createEffect((speed) => {
    // casting player doesn't support speed change, so we need to reset it
    if (speed !== state.speed && sourceProps.chromeCast) {
      resetPlayer($playerEl);
    }
    return state.speed;
  }, state.speed);

  function handleFullscreenChange() {
    setState({ fullscreen: document.fullscreenElement === solidPlayer });
  }

  function handlePlay() {
    setState({ paused: false });
  }

  function handlePause() {
    setState({ paused: true });
  }

  function handleVolumeChange() {
    setState({ muted: player.muted, volume: player.volume });
  }

  function handleDurationChange() {
    setState({ duration: !isLiveMemo() ? player.duration : 100 });
  }

  function handleTimeUpdate() {
    if (!isLiveMemo()) {
      setState({ currentTime: player.currentTime });
    }
  }

  function handleStatusChange(status: PlayerStatus) {
    if (status === "playing") {
      setState({ showPoster: false });
    }

    setState({ buffering: status === "loading" });
  }

  function handleRateChange(speed: number) {
    if (!isLiveMemo()) {
      setState({ speed: speed.toString() });
    }
  }

  function handleStateItemChange(key: PlayerStateKeys, value: any) {
    switch (key) {
      case "speed":
        // cannot change speed on live streams
        if (!isLiveMemo()) {
          player.setSpeed(parseFloat(value));
        }
        break;

      case "fullscreen":
        if (value) {
          if ("requestFullscreen" in solidPlayer) {
            solidPlayer.requestFullscreen();
          } else if ("enterFullscreen" in player) {
            // fallback for Safari
            player.enterFullscreen();
          }
        } else {
          document.exitFullscreen();
        }
        break;

      case "casting": {
        // changing casting state will cause destruction of the player, which in it's turn will cause
        // the cast session to be terminated and thus switching back to the local player, we need to
        // supress it when it was speed change that caused this sequence of events
        if (value || state.speed === player.speed.toString()) {
          setState({ casting: value });
        }
        break;
      }

      default:
        setState(key, value);
    }
  }

  function handleError(error: Error) {
    console.error(error);
  }

  if (sourceProps.ref) {
    sourceProps.ref({
      play: () => player.play(),
      pause: () => player.pause(),
      isPlaying: () => !player.paused,
      rewind: (time = 15) => player.seekTo(player.currentTime - time),
      fastForward: (time = 15) => player.seekTo(player.currentTime + time),
      reset: () => player.seekTo(0),
    });
  }

  async function resetPlayer($el: HTMLDivElement) {
    const src = currentSource()?.src;

    if (src) {
      if (player?.src) {
        destroyPlayer();
      }
      if ($el) {
        $playerEl = $el;

        createPlayer(
          $el,
          // make sure to override the props with the current state
          {
            autoplay: !state.paused || videoProps.autoplay,
            volume: state.volume,
            muted: state.muted,
            playbackRate: parseFloat(state.speed),
            currentTime: isUnrelatedSource() ? 0 : state.currentTime,
            poster: props.poster,
            title: currentLanguage()?.title,
          }
        );
      }
      if (player) {
        await player.loadSource(src);
        if ((props.autoplay && !state.currentTime) || !state.paused) {
          await player.play();
        }
      }
    }
  }

  function createPlayer(
    $el: HTMLDivElement,
    props?: Partial<VideoPlayerProps>
  ) {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("fullscreenerror", handleFullscreenChange);

    player = state.casting
      ? new ChromecastPlayer($el, merge(videoProps, props))
      : isLiveMemo()
      ? new HlsPlayer($el, merge(videoProps, props))
      : new WebPlayer($el, merge(videoProps, props));

    player.on("play", handlePlay);
    player.on("pause", handlePause);
    player.on("volumechange", handleVolumeChange);
    player.on("durationchange", handleDurationChange);
    player.on("ratechange", handleRateChange);
    player.on("timeupdate", handleTimeUpdate);
    player.on("status", handleStatusChange);
    player.on("error", handleError);

    sourceProps.onFinished && player.on("ended", sourceProps.onFinished);
  }

  function destroyPlayer() {
    document.removeEventListener("fullscreenchange", handleFullscreenChange);
    document.removeEventListener("fullscreenerror", handleFullscreenChange);

    player.off("play", handlePlay);
    player.off("pause", handlePause);
    player.off("volumechange", handleVolumeChange);
    player.off("durationchange", handleDurationChange);
    player.off("ratechange", handleRateChange);
    player.off("timeupdate", handleTimeUpdate);
    player.off("status", handleStatusChange);
    player.off("error", handleError);

    sourceProps.onFinished && player.off("ended", sourceProps.onFinished);

    player.destroy();
    // @ts-ignore
    player = null;
  }

  createIdleTimer({
    idleTimeout: 5000,
    onIdle: () => setState({ isIdle: true }),
    onActive: () => setState({ isIdle: false }),
  });

  onCleanup(() => {
    destroyPlayer();
  });

  const [shareButtonClicked, setShareButtonClicked] = createSignal(false);

  createEffect(() => {
    void shareButtonClicked();

    if (shareButtonClicked()) {
      const timeout = setTimeout(() => setShareButtonClicked(false), 5000);

      return () => clearTimeout(timeout);
    }
  });

  return (
    <div
      classList={{
        [styles.root]: true,
        // [styles.isFullscreen]: state.fullscreen,
        dark: props.theme === "dark",
      }}
      ref={(el) => (solidPlayer = el)}
    >
      <div class={styles.player} ref={resetPlayer}></div>

      {state.buffering && <Spinner className={styles.spinner} />}

      {props.poster && state.showPoster && (
        <div class={styles.poster}>
          <img src={props.poster} role="presentation" />
        </div>
      )}

      {sourceProps.controls && (
        <div
          classList={{ [styles.overlay]: true, [styles.isIdle]: state.isIdle }}
        >
          <div class={styles.overlayContent}>
            {props.onBack != null && (
              <button
                class={styles.backButton}
                onClick={() => props.onBack?.()}
              >
                <BackButton />
              </button>
            )}

            {currentLanguage()?.title && (
              <div class={styles.title}>{currentLanguage()?.title}</div>
            )}

            {props.onShare != null && (
              <button
                class={styles.shareButton}
                onClick={() => {
                  setShareButtonClicked(true);

                  props.onShare?.();
                }}
              >
                {shareButtonClicked() ? <CopiedButton /> : <ShareButton />}
              </button>
            )}

            {isMobile() && (
              <div class={styles.playButton}>
                <button
                  class={vanillaStyles.IconButton}
                  disabled={state.buffering}
                  onClick={() =>
                    state.paused ? player.play() : player.pause()
                  }
                >
                  {state.paused ? <PlayButton /> : <PauseButton />}
                </button>
              </div>
            )}

            <button
              class={styles.overlayButton}
              disabled={state.buffering}
              onClick={() => (state.paused ? player.play() : player.pause())}
            />

            <div
              classList={{
                [styles.timeline]: true,
              }}
            >
              <Timeline
                duration={state.duration}
                currentTime={state.currentTime}
                onChange={(time) => player.seekTo(time)}
                interactive={!isLiveMemo()}
              />

              {!isLiveMemo() && (
                <div class={styles.timestamps}>
                  {`${formatTime(
                    state.currentTime,
                    state.duration
                  )} / ${formatTime(state.duration, state.duration)}`}
                </div>
              )}
              {isLiveMemo() && <div class={styles.timestamps}>Live</div>}
            </div>

            <div
              class={vanillaStyles.controls({
                idle: state.isIdle,
                type: "video",
              })}
            >
              <VolumeRocker
                level={state.volume}
                muted={state.muted}
                onLevelChange={(level) => player.setVolume(level)}
                onMutedChange={(muted) =>
                  muted ? player.mute() : player.unmute()
                }
              />

              {isDesktop() && (
                <PlaybackControls
                  onTimeChange={(time) => player.seekTo(time)}
                  onPlayPause={(shouldPause) =>
                    shouldPause ? player.pause() : player.play()
                  }
                  hideShiftControls={isLiveMemo()}
                />
              )}

              <SettingsControls onStateItemChange={handleStateItemChange} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
