import { Dialog } from "@kobalte/core";
import { createEffect, createSignal } from "solid-js";
import { BasicArrowRight, BasicCheck } from "src/icons";
import { ActiveOption, MenuButtonProps, Option } from "./types";
import { normalizeOption, useColorScheme } from "src/infra";
import c from "classnames";

import styles from "./MobileMenuButton.module.scss";

export function MobileMenuButton(props: MenuButtonProps) {
  const { onOpenChange, onSelect } = props;
  const { colorScheme } = useColorScheme();

  const [activeItem, setActiveItem] = createSignal<ActiveOption>(
    normalizeOption(props.options) as ActiveOption
  );

  createEffect(() => {
    setActiveItem(normalizeOption(props.options) as ActiveOption);
  });

  function handleItemClick(item: Option | ActiveOption) {
    if (item.items) {
      setActiveItem(item as ActiveOption);
    } else {
      onSelect?.(item, item.parent?.id ?? item.parent?.label);
      handleOpenChange(false);
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setActiveItem(normalizeOption(props.options) as ActiveOption);
    }
    onOpenChange?.(isOpen);
  }

  function renderContent() {
    return (
      <>
        <Dialog.Overlay
          class={c(styles.overlay, !props.inPortal && styles.isFullscreen)}
        />
        <Dialog.Content
          class={c(
            styles.mobileMenu,
            props.open && styles.isOpen,
            !props.inPortal && styles.isFullscreen,
            colorScheme() === "dark" && styles.isDark
          )}
        >
          <button
            class={styles.handle}
            onClick={() => handleOpenChange(!props.open)}
          />

          <div class={styles.title}>{activeItem().label}</div>

          {activeItem().items.map((item: Option) => {
            const isSelected = item.value === activeItem().value;

            return (
              <>
                {item.items ? (
                  <div
                    class={styles.item}
                    onClick={() => handleItemClick(item)}
                  >
                    <span class={styles.itemLabel}>{item.label}</span>
                    <span
                      // class={styles.itemValue}
                    >
                      {item.value}
                    </span>
                    <span class={styles.itemIcon}>
                      <BasicArrowRight />
                    </span>
                  </div>
                ) : (
                  <div
                    classList={{
                      [styles.item]: true,
                      // [styles.itemSelected]: isSelected,
                    }}
                    onClick={() => handleItemClick(item)}
                  >
                    <span
                      classList={{
                        [styles.itemIndicator]: true,
                        [styles.itemIndicatorSelected]: isSelected,
                      }}
                    >
                      <BasicCheck />
                    </span>

                    <span class={styles.itemLabel}>{item.label}</span>
                  </div>
                )}
              </>
            );
          })}
        </Dialog.Content>
      </>
    );
  }

  return (
    <Dialog.Root open={props.open} onOpenChange={handleOpenChange}>
      {props.children && (
        <Dialog.Trigger class={props.class}>{props.children}</Dialog.Trigger>
      )}

      {props.inPortal && <Dialog.Portal>{renderContent()}</Dialog.Portal>}
      {!props.inPortal && renderContent()}
    </Dialog.Root>
  );
}
