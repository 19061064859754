import { useResponsive } from "src/infra";
import { MobileVolumeRocker } from "./MobileVolumeRocker";
import { DesktopVolumeRocker } from "./DesktopVolumeRocker";
import { VolumeRockerProps } from "./types";

export function VolumeRocker(props: VolumeRockerProps) {
  const { isMobile } = useResponsive();

  return (
    // ternary doesn't work with SolidJS signals... WTF?
    <>
      {isMobile() && <MobileVolumeRocker {...props} />}
      {!isMobile() && <DesktopVolumeRocker {...props} />}
    </>
  );
}
