import { SolidPlayerProps } from "src/types";

export interface PlayerState {
  isIdle: boolean;
  muted: boolean;
  volume: number;
  currentTime: number;
  duration: number;
  paused: boolean;
  buffering: boolean;
  fullscreen: boolean;
  casting: boolean;
  speed: string;
  language: string;
  quality: string;
  captionsIsActive: boolean;
  showPoster: boolean;
  props: SolidPlayerProps;
}

export const DEFAULT_STATE: Omit<PlayerState, "props"> = {
  isIdle: false,
  muted: false,
  volume: 1,
  currentTime: 0,
  duration: 0,
  paused: true,
  buffering: false,
  fullscreen: false,
  casting: false,
  speed: "1",
  language: "GEO",
  quality: "Auto",
  captionsIsActive: false,
  showPoster: true,
};

export type PlayerStateKeys = keyof PlayerState;
