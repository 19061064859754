import {
  SolidPlayerLanguage,
  SolidPlayerQuality,
  SolidPlayerSource,
} from "src/types";
import { usePlayerContext } from "src/infra";
import { createEffect, createMemo, createSignal, untrack } from "solid-js";
import { isEqual } from "lodash";

type UseCurrentSource = {
  currentLanguage: () => SolidPlayerLanguage | null;
  currentQuality: () => SolidPlayerQuality | null;
  currentSource: () => SolidPlayerSource | null;
  isUnrelatedSource: () => boolean;
};

const useCurrentSource = (
  languages: SolidPlayerLanguage[]
): UseCurrentSource => {
  const [state] = usePlayerContext();
  const [prevLanguages, setPrevLanguages] = createSignal(
    state.props?.languages
  );
  const [hasQualityChanged, setHasQualityChanged] = createSignal(false);

  const currentLanguage = createMemo(
    () =>
      languages.find(
        (lang: SolidPlayerLanguage) => lang.label === state.language
      ) ??
      languages[0] ??
      null
  );

  const currentQuality = createMemo(
    () =>
      currentLanguage()?.qualities.find(
        (qual: SolidPlayerQuality) =>
          qual.label === state.quality || state.quality === "Auto"
      ) ??
      currentLanguage().qualities[0] ??
      null
  );

  createEffect(() => {
    void state.props?.languages;

    untrack(() => {
      if (!isEqual(prevLanguages(), state.props?.languages)) {
        setPrevLanguages(state.props?.languages);
      }
    });
  });

  createEffect(() => {
    void currentQuality();
    setHasQualityChanged(true);
  });

  return {
    currentLanguage,
    currentQuality,
    currentSource: () => currentQuality()?.sources[0] ?? null,
    isUnrelatedSource: () =>
      hasQualityChanged() && !isEqual(state.props?.languages, prevLanguages()),
  };
};

export default useCurrentSource;
